export default {
  'memberAndPartner.name': 'Executive committee',
  'memberAndPartner.title': 'Executive committee',
  'executiveCommittee.code': 'Code Executive committee',
  'executiveCommittee.idCard': 'Id card',
  'executiveCommittee.name': 'Name',
  'executiveCommittee.dayOfBirth': 'Day of birth',
  'executiveCommittee.gender': 'Gender',
  'executiveCommittee.phoneNumber': 'Phonenumber',
  'executiveCommittee.emailAddress': 'Email',
  'executiveCommittee.avatar': 'Avatar',
  'executiveCommittee.placeholder.dayOfBirth': 'Please enter day of birth',
  'executiveCommittee.title-1': 'Personal information',
  'executiveCommittee.add': 'Add Executive committee',
  'executiveCommittee.view': 'Information Executive committee',
  'executiveCommittee.edit': 'Edit Executive committee',
  'executiveCommittee.title-2': 'Workunit information',
  'executiveCommittee.title-3': 'Title',
  'executiveCommittee.workUnit': 'Workunit',
  'executiveCommittee.title': 'Title',
  'executiveCommittee.prorogues': 'Prorogue',
  'executive-committees.placeholder.prorogues': 'Please enter prorogues',
  'executiveCommittee.status': 'Status',
  'executiveCommittee.action': 'Action',
  'executiveCommittee.info': 'Detail Executive committee',
  'executiveCommittee.non-delete.title': 'Can not delete',
  'executiveCommittee.non-delete.content': 'Can not delele thus Executive committee active',
  'executiveCommittee.delete.title': 'Delete Executive committee',
  'executiveCommittee.delete.content': 'Do you want to delete this Executive committee',
  'executiveCommittee.listOfExecutiveCommittee': 'List of Executive committee',
};
