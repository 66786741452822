export default {
  'laudatory.name': 'Khen thưởng',
  'laudatory.list': 'Danh sách khen thưởng',
  'laudatory.laudatory': 'Khen thưởng',
  'laudatory.suggest': 'Đề xuất',
  'laudatory.listLaudatory': 'Danh sách khen thưởng',
  'laudatory.reasonSuggest': 'Lý do khen thưởng',
  'laudatory.code': 'Mã Đảng viên',
  'laudatory.SuggestName': 'Họ và tên',
  'laudatory.club': 'Chi bộ',
  'laudatory.proponentTime': 'Ngày đề xuất',
  'laudatory.propounder': 'Người đề xuất',
  'laudatory.status': 'Trạng thái',
  'laudatory.status.suggest':
    '{status,select, 1 {Chờ duyệt} 2{Không xác định} 3 {Đã từ chối}  other {Không xác định}}',
  'laudatory.rejectReason': 'Lý do từ chối',
  'laudatory.idCard': 'Mã thẻ',
  'laudatory.grade': 'Khối lớp',
  'laudatory.gender': 'Giới tính',
  'laudatory.male': 'Nam',
  'laudatory.female': 'Nữ',
  'laudatory.dayOfBirth': 'Ngày sinh',
  'laudatory.workUnit': 'Chi đội',
  'laudatory.phoneNumber': 'Số điện thoại',
  'laudatory.position': 'Quan hệ',
  'laudatory.emailAddress': 'Email',
  'laudatory.approverTime': 'Ngày hiệu lực',
  'laudatory.approver': 'Người đề xuất',
  'laudatory.approverAccept': 'Người duyệt',
  'laudatory.personalInformation': 'Thông tin cá nhân',
  'laudatory.activities': 'Hoạt động',
  'laudatory.reward': 'Khen thưởng',
  'laudatory.avatar': 'Ảnh đại diện',
  'laudatory.score': 'Số điểm',
  'laudatory.rank': 'Hạng Đảng viên',
  'laudatory.rewardName': 'Thành tích',
  'laudatory.detailPage': 'Xem chi tiết',
  'laudatory.changeClass': 'Chuyển chi bộ',
  'laudatory.year': 'Năm',
  'laudatory.quarter': 'Quý',
  'laudatory.placeholderQuarter': 'Chọn quý',
  'laudatory.placeholderYear': 'Chọn năm',
  'laudatory.totalScore': 'Tổng điểm',
};
