export default {
  'statisticsAgeReport.name': 'Statistics age report',
  'statisticsAgeReport.code': 'Member code',
  'statisticsAgeReport.fullName': 'Full name',
  'statisticsAgeReport.age': 'Age party',
  'statisticsAgeReport.year': 'year',
  'statisticsAgeReport.month': 'month',
  'statisticsAgeReport.day': 'day',
  'statisticsAgeReport.years': 'years',
  'statisticsAgeReport.months': 'months',
  'statisticsAgeReport.days': 'days',
  'statisticsAgeReport.type': 'Type',
  'statisticsAgeReport.activityStatus': 'Activity status',
  'statisticsAgeReport.endTime': 'Date',
  'statisticsAgeReport.count': 'Quantity',
};
