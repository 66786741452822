enum PermissionCode {
  CAREERS_CREATE = 'CareersCreate',
  CAREERS_DELETE = 'CareersDelete',
  CAREERS_SEARCH = 'CareersSearch',
  CAREERS_UPDATE = 'CareersUpdate',
  CAREERS_VIEW = 'CareersView',
  CERTIFICATES_CREATE = 'CertificatesCreate',
  CERTIFICATES_DELETE = 'CertificatesDelete',
  CERTIFICATES_SEARCH = 'CertificatesSearch',
  CERTIFICATES_UPDATE = 'CertificatesUpdate',
  CERTIFICATES_VIEW = 'CertificatesView',
  CLUBS_CREATE = 'ClubsCreate',
  CLUBS_CREATE_MEMBER = 'ClubsCreateMember',
  CLUBS_CREATE_PARTNER = 'ClubsCreatePartner',
  CLUBS_DELETE = 'ClubsDelete',
  CLUBS_DELETE_MEMBER = 'ClubsDeleteMember',
  CLUBS_DELETE_PARTNER = 'ClubsDeletePartner',
  CLUBS_INVITE = 'ClubsInvite',
  CLUBS_UPDATE = 'ClubsUpdate',
  CLUBS_UPDATE_MEMBER = 'ClubsUpdateMember',
  CLUBS_UPDATE_PARTNER = 'ClubsUpdatePartner',
  CLUBS_SEARCH = 'ClubsSearch',
  CLUBS_VIEW = 'ClubsView',
  CONTACTS_UPDATE = 'ContactsUpdate',
  ENTERPRISES_CREATE = 'EnterprisesCreate',
  ENTERPRISES_DELETE = 'EnterprisesDelete',
  ENTERPRISES_SEARCH = 'EnterprisesSearch',
  ENTERPRISES_UPDATE = 'EnterprisesUpdate',
  ENTERPRISES_VIEW = 'EnterprisesView',
  EXECUTIVE_COMMITTEES_CREATE = 'ExecutiveCommitteesCreate',
  EXECUTIVE_COMMITTEES_DELETE = 'ExecutiveCommitteesDelete',
  EXECUTIVE_COMMITTEES_SEARCH = 'ExecutiveCommitteesSearch',
  EXECUTIVE_COMMITTEES_UPDATE = 'ExecutiveCommitteesUpdate',
  EXECUTIVE_COMMITTEES_VIEW = 'ExecutiveCommitteesView',
  FEES_CREATE = 'FeesCreate',
  FEES_DELETE = 'FeesDelete',
  FEES_SEARCH = 'FeesSearch',
  FEES_UPDATE = 'FeesUpdate',
  FEES_VIEW = 'FeesView',
  FIELDS_CREATE = 'FieldsCreate',
  FIELDS_DELETE = 'FieldsDelete',
  FIELDS_SEARCH = 'FieldsSearch',
  FIELDS_UPDATE = 'FieldsUpdate',
  FIELDS_VIEW = 'FieldsView',
  MARKETS_CREATE = 'MarketsCreate',
  MARKETS_DELETE = 'MarketsDelete',
  MARKETS_SEARCH = 'MarketsSearch',
  MARKETS_UPDATE = 'MarketsUpdate',
  MARKETS_VIEW = 'MarketsView',
  MEDIAS_CREATE = 'MediasCreate',
  MEDIAS_DELETE = 'MediasDelete',
  MEMBERS_CREATE = 'MembersCreate',
  MEMBERS_DELETE = 'MembersDelete',
  MEMBERS_SEARCH = 'MembersSearch',
  MEMBERS_VIEW = 'MembersView',
  MEMBERS_UPDATE = 'MembersUpdate',
  NOTIFICATIONS_CREATE = 'NotificationsCreate',
  NOTIFICATIONS_DELETE = 'NotificationsDelete',
  NOTIFICATIONS_SEARCH = 'NotificationsSearch',
  NOTIFICATIONS_UPDATE = 'NotificationsUpdate',
  NOTIFICATIONS_VIEW = 'NotificationsView',
  ORGANIZATIONS_CREATE = 'OrganizationsCreate',
  ORGANIZATIONS_DELETE = 'OrganizationsDelete',
  ORGANIZATIONS_SEARCH = 'OrganizationsSearch',
  ORGANIZATIONS_UPDATE = 'OrganizationsUpdate',
  ORGANIZATIONS_VIEW = 'OrganizationsView',
  PARTNERS_CREATE = 'PartnersCreate',
  PARTNERS_DELETE = 'PartnersDelete',
  PARTNERS_SEARCH = 'PartnersSearch',
  PARTNERS_VIEW = 'PartnersView',
  PARTNERS_UPDATE = 'PartnersUpdate',
  RANKS_SEARCH = 'RanksSearch',
  RANKS_UPDATE = 'RanksUpdate',
  RANKS_VIEW = 'RanksView',
  ROLES_CREATE = 'RolesCreate',
  ROLES_DELETE = 'RolesDelete',
  ROLES_EXPORT = 'RolesExport',
  ROLES_SEARCH = 'RolesSearch',
  ROLES_UPDATE = 'RolesUpdate',
  ROLES_VIEW = 'RolesView',
  SCHEDULES_CANCEL = 'SchedulesCancel',
  SCHEDULES_CREATE = 'SchedulesCreate',
  SCHEDULES_DELETE = 'SchedulesDelete',
  SCHEDULES_SEARCH = 'SchedulesSearch',
  SCHEDULES_UPDATE = 'SchedulesUpdate',
  SCHEDULES_VIEW = 'SchedulesView',
  SPONSORS_CREATE = 'SponsorsCreate',
  SPONSORS_DELETE = 'SponsorsDelete',
  SPONSORS_SEARCH = 'SponsorsSearch',
  SPONSORS_UPDATE = 'SponsorsUpdate',
  SPONSORS_VIEW = 'SponsorsView',
  TARGET_CUSTOMERS_CREATE = 'TargetCustomersCreate',
  TARGET_CUSTOMERS_DELETE = 'TargetCustomersDelete',
  TARGET_CUSTOMERS_SEARCH = 'TargetCustomersSearch',
  TARGET_CUSTOMERS_UPDATE = 'TargetCustomersUpdate',
  TARGET_CUSTOMERS_VIEW = 'TargetCustomersView',
  USERS_CREATE = 'UsersCreate',
  USERS_DELETE = 'UsersDelete',
  USERS_EXPORT = 'UsersExport',
  USERS_SEARCH = 'UsersSearch',
  USERS_UPDATE = 'UsersUpdate',
  USERS_VIEW = 'UsersView',
  REPRESENTS_CREATE = 'RepresentsCreate',
  REPRESENTS_DELETE = 'RepresentsDelete',
  REPRESENTS_SEARCH = 'RepresentsSearch',
  REPRESENTS_UPDATE = 'RepresentsUpdate',
  REPRESENTS_VIEW = 'RepresentsView',

  ACTIVITIES_CREATE = 'ActivitiesCreate',
  ACTIVITIES_CREATE_SUB = 'ActivitiesCreateSub',
  ACTIVITIES_DELETE = 'ActivitiesDelete',
  ACTIVITIES_DELETE_SUB = 'ActivitiesDeleteSub',
  ACTIVITIES_SEARCH = 'ActivitiesSearch',
  ACTIVITIES_UPDATE = 'ActivitiesUpdate',
  ACTIVITIES_UPDATE_SUB = 'ActivitiesUpdateSub',
  ACTIVITIES_VIEW = 'ActivitiesView',

  ACTIVITIES_UPDATE_OR_UPDATE_SUB = 'ActivitiesUpdateOrUpdateSub',
  ACTIVITIES_VIEW_FORM = 'ActivitiesViewForm',
  ACTIVITIES_SUB_VIEW_FORM = 'ActivitiesSubViewForm',

  MERITBADGES_CREATE = 'MeritBadgesCreate',
  MERITBADGES_DELETE = 'MeritBadgesDelete',
  MERITBADGES_SEARCH = 'MeritBadgesSearch',
  MERITBADGES_UPDATE = 'MeritBadgesUpdate',
  MERITBADGES_VIEW = 'MeritBadgesView',

  REPORTS_CREATE = 'ReportsCreate',
  REPORTS_DELETE = 'ReportsDelete',
  REPORTS_SEARCH = 'ReportsSearch',
  REPORTS_VIEW = 'ReportsView',

  REWARDS_CREATE = 'RewardsCreate',
  REWARDS_DELETE = 'RewardsDelete',
  REWARDS_SEARCH = 'RewardsSearch',
  REWARDS_VIEW = 'RewardsView',

  SUGGESTS_CREATE = 'SuggestsCreate',
  SUGGESTS_DELETE = 'SuggestsDelete',
  SUGGESTS_SEARCH = 'SuggestsSearch',
  SUGGESTS_VIEW = 'SuggestsView',
  SUGGESTS_APPROVE = 'SuggestsApprove',
  SUGGESTS_REJECT = 'SuggestsReject',

  REWARDS_SUGGEST_DETAIL_PAGE = 'RewardsSuggestsDetailPage',

  CHANGE_CLASS = 'ClubsChangeClass',

  FEEDBACKS_SEARCH = 'FeedbacksSearch',
  FEEDBACKS_VIEW = 'FeedbacksView',
  FEEDBACKS_REPLY = 'FeedbacksReply',
  FEEDBACKS_VIEW_DETAIL_PAGE = 'FeedbacksViewDetailPage',

  STATISTICS_AGE_REPORT = 'StatisticsAgeReport',
  CLUBS_CHANGE_CLUB = 'ClubsChangeClub',
}

export default PermissionCode;
