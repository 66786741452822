import PermissionCode from '@modules/roles/contantsPermissions';

export interface IPermissionValue {
  FE_KEY: PermissionCode;
  BE_KEY: Array<PermissionCode>;
  TYPE: 'AND' | 'OR';
}

export const custom: { data: Array<IPermissionValue> } = {
  data: [
    {
      FE_KEY: PermissionCode.ACTIVITIES_UPDATE_OR_UPDATE_SUB,
      BE_KEY: [PermissionCode.ACTIVITIES_UPDATE, PermissionCode.ACTIVITIES_UPDATE_SUB],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionCode.ACTIVITIES_VIEW_FORM,
      BE_KEY: [
        PermissionCode.ACTIVITIES_VIEW,
        PermissionCode.ACTIVITIES_CREATE,
        PermissionCode.ACTIVITIES_UPDATE,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionCode.ACTIVITIES_SUB_VIEW_FORM,
      BE_KEY: [
        PermissionCode.ACTIVITIES_VIEW,
        PermissionCode.ACTIVITIES_CREATE_SUB,
        PermissionCode.ACTIVITIES_UPDATE_SUB,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionCode.REWARDS_SUGGEST_DETAIL_PAGE,
      BE_KEY: [PermissionCode.REPORTS_VIEW, PermissionCode.SUGGESTS_VIEW],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionCode.FEEDBACKS_VIEW_DETAIL_PAGE,
      BE_KEY: [PermissionCode.FEEDBACKS_REPLY, PermissionCode.FEEDBACKS_VIEW],
      TYPE: 'OR',
    },
  ],
};
