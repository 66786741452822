export default {
  // Notification

  'notification.name': 'Thông báo',
  'notification.title': 'Danh sách thông báo',
  'notification.table.title': 'Tiêu đề',
  'notification.table.method': 'Hình thức',
  'notification.table.sendTime': 'Thời gian gửi',
  'notification.table.notificationRecipient': 'Số người nhận',
  'notification.table.status': 'Trạng thái',
  'notification.table.creator': 'Người tạo',
  'notification.table.action': 'Hành động',
  'notification.table.createdAt': 'Ngày tạo',
  'notification.table.status.id': '{status, select, 1 {Chưa gửi} 2 {Đã gửi} other {}}',
  'notification.confirm.title.delete': 'Bạn muốn xóa thông báo?',

  // Page
  'notification.add.name': 'Thêm mới',
  'notification.edit.name': 'Cập nhật',
  'notification.add.title.name': 'Họ và tên',
  'notification.add.title.emailAddress': 'Email',
  'notification.add.title.message.rule': 'Nhập',
  'notification.add.title.add': 'Thêm thông báo mới',
  'notification.add.title.update': 'Cập nhật thông báo',
  'notification.add.title.content': 'Nội dung:',
  'notification-page.table.code': 'Mã hội viên',
  'notification-page.table.name': 'Tên hội viên',
  'notification-page.table.emailAddress': 'Email',
  'notification-page.table.type': 'Đối tượng',
  'notification-page.table.action': 'Hành động',
  'notification-page.form.title': 'Tiêu đề',
  'notification-page.form.inputPlaceholder': 'Thông báo về việc bổ nhiệm nhân sự mới',
  'notification-page.form.message': 'Nhập hình thức',
  'notification-page.form.method': 'Hình thức:',
  'notification-page.form.sendTime': 'Thời gian gửi:',
  'notification-page.form.sendTime.rules.message': 'Nhập thời gian gửi',
  'notification-page.form.immediately': 'Ngay lập tức',
  'notification-page.form.option': 'Tùy chọn',
  'notification-page.form.receiver': 'Người nhận:',
  'notification-page.form.receiver.title.1': 'Thành viên của hội',
  'notification-page.form.receiver.title.2': 'Người ngoài hệ thống',
  'notification-page.form.addRecipients': 'Thêm người nhận',
  'notification-page.form.program': 'Chương trình',
  'notification-page.form.program.placeholder': 'Thêm chương trình để chọn',

  // PageInfo
  'notification.info.name': 'Chi tiết',
  'notification.info.participants': 'Người tham dự',
  'notification-page-info.content.title': 'Tiêu đề',
  'notification-page-info.content.method': 'Hình thức',
  'notification-page-info.content.sendTime': 'Thời gian gửi',
  'notification-page-info.content.content': 'Nội dung',
  'notification-page-info.table.code': 'Mã hội viên',
  'notification-page-info.table.name': 'Tên hội viên',
  'notification-page-info.table.emailAddress': 'Email',
  'notification-page-info.table.type': 'Đối tượng',
  'notification-page-info.table.market': 'Thị trường',
  'notification-page-info.table.targetCustomer': 'Khách hàng mục tiêu',
  'notification-page-info.table.enterpriseScale': 'Quy mô doanh nghiệp',
  'notification-page-info.table.confirm': 'Xác nhận',
  'notification-page-info.table.confirm.id':
    '{status, select, 1 {Chưa xác nhận} 2 {Xác nhận} 3 {Từ chối} other {}}',

  // Modal
  'modal-notification-page.table.title': 'Thêm người nhận',
  'modal-notification-page.table.name': 'Tên hội viên',
  'modal-notification-page.table.code': 'Mã',
  'modal-notification-page.table.grade': 'Khối',
  'modal-notification-page.table.workUnit': 'Chi đội',
  'modal-notification-page.table.emailAddress': 'Email',
  'modal-notification-page.table.type': 'Đối tượng',
  'modal-notification-page.table.memberFields': 'Lĩnh vực',
  'modal-notification-page.table.memberMarkets': 'Thị trường',
  'modal-notification-page.table.memberTargetCustomers': 'Khách hàng mục tiêu',
  'modal-notification-page.table.enterpriseScale': 'Quy mô doanh nghiệp',
  'modal-notification-page.table.type.id':
    '{status, select, 1 {Hội viên đơn vị} 2 {Hội viên liên kết} 3 {Đối tác doanh nghiệp} 4 {Ban chấp hành} 5 {Chi bộ} 6 {Đảng viên} 7 {Đối tác cá nhân} 8 {Cấp ủy} 99 {Người ngoài hệ thống} other {} }',
  'modal-notification-page.table.type.id.1': 'Hội viên đơn vị',
  'modal-notification-page.table.type.id.2': 'Hội viên liên kết',
  'modal-notification-page.table.type.id.3': 'Đối tác doanh nghiệp',
  'modal-notification-page.table.type.id.4': 'Ban chấp hành',
  'modal-notification-page.table.type.id.5': 'Chi bộ',
  'modal-notification-page.table.type.id.6': 'Đội viên',
  'modal-notification-page.table.type.id.7': 'Đối tác cá nhân',
  'modal-notification-page.table.type.id.8': 'Cấp ủy',
  'modal-notification-page.table.type.id.99': 'Người ngoài hệ thống',

  'modal-notification-page.table.enterpriseScale.id':
    '{status, select, 1 {50 - 100 người} 2 {100 - 200 người} 3 {200 - 500 người} 4 {500 người} other {--}}',

  // updade on 18/10/2023
  'notification.info': 'Thông tin',
  'notification.receiver': 'Người nhận',
  'notification.requiredReceiver': 'Nhập người nhận',
  'notification.importFile': 'Tải lên file:',
  'notification.email-invalid': 'Email không đúng định dạng',
  'notification.email-already': 'Trùng email',
  'notification.email-required': 'Email không được bỏ trống',
  'notification.placeholder-name': 'Nhập họ và tên',
  'notification.placeholder-email': 'Nhập email',
  'modal-notification-page.useLastMember': 'Sử dụng người nhận cũ',
  'modal-notification-page.chooseMeeting': 'Chọn lịch họp',
  'modal-notification-page.chooseNotification': 'Chọn thông báo',
  'modal-notification-page.fullName': 'Họ và tên',
};
