export default {
  'action.status.param':
    '{status,select, 1 {Not started} 2 {In progress} 3 {Ended} other {Undefined}}',
  'action.name': 'Activity',
  'action.listAction': 'List of activities',
  'action.add': 'Add activity',
  'action.buttonSubmit': 'Add',
  'action.buttonEdit': 'Update',
  'action.time': 'Time',
  'action.startDay': 'From date',
  'action.endDay': 'To date',
  'action.view': 'View activity details',
  'action.edit': 'Update activity',
  'action.btnViewMember': 'Member list',
  'action.listMember': 'Member list',
  'action.totalMember': 'Total members',
  'action.totalMemberActive': 'Number of active members',
  'action.totalMemberNotActive': 'Number of inactive members',
  'action.title': 'Activity name',
  'action.timeAction': 'Activity time',
  'action.content': 'Activity content',
  'action.meritBadgeIds': 'Merit badges',
  'action.score': 'Score',
  'action.clubIds': 'Members participating',
  'action.validatorTitle': 'Activity name cannot be empty',
  'action.validatorContent': 'Activity content cannot be empty',
  'action.startTime': 'Start Time',
  'action.endTime': 'End Time',
  'action.creator': 'Creator',
  'action.status': 'Status',
  'action.totalMemberTable': 'Total members',
  'action.allMember': 'All members',
  'action.delete.title': 'Delete activity',
  'action.delete.content': 'Do you want to delete this activity?',
  'action.non-delete.title': 'Can not delete',
  'action.non-delete.content': 'Can not delete activity had started',
  'action.validatorTime': 'Start time must greater than now',
  'actionChild.code': 'Member code',
  'actionChild.name': 'Member name',
  'actionChild.emailAddress': 'Email',
  'action.grade': 'Grade',
  'action.unionOfDistrict': 'Union district',
  'action.detachment': 'Detachment',
  'action.createSource': 'Create source',
  'action.tableChild.title': 'Child activity name',
  'action.tableChild.meritBadgeIds': 'Merit',
  'action.tableChild.score': 'Score',
  'action.tableChild.startTime': 'Start time',
  'action.tableChild.endTime': 'End time',
  'action.tableChild.grades': 'Grade',
  'action.tableChild.status': 'Status',
  'actionChild.status.param':
    '{status,select, 1 {Not confirm} 2 {Confirmed} 3 {Reject} other {Không xác định}}',
  'actionChild.confirm': 'Status confirm',
  'action.workunits': 'Class',
  'actionChild.confirmTime': 'Confirm time',
  'action.list-member': 'List of members',
  'action.scoreCollected': 'Score',
  'action.scoreDay': 'Date',
  'laudatory.modalHistory': 'Score history',
  'action.attachs': 'Attach file',
  'action.address': 'Address',
};
