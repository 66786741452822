import React from 'react';
import { Bookmark } from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerListExecutiveCommittee: IRouter = {
  path: '/clubs/list-executive-committee/:id', ///Cấp ủy
  loader: React.lazy(() => import('./index')),
  exact: true,
  activePath: '/clubs/list-executive-committee/',
  menu: {
    icon: <Bookmark />,
  },
  generatePath(id) {
    return `/clubs/list-executive-committee/${id}`;
  },
  generateMenu(id) {
    return Boolean(id);
  },
  name: 'clubs.list-represents', //translate here for breadcrumb and sidebar
};
