export default {
  'laudatory.name': 'Laudatory',
  'laudatory.list': 'List of reward',
  'laudatory.laudatory': 'Laudatory',
  'laudatory.suggest': 'Suggest',
  'laudatory.listLaudatory': 'List of laudatory',
  'laudatory.reasonSuggest': 'Reason of laudatory',
  'laudatory.code': 'Code member',
  'laudatory.SuggestName': 'Fullname',
  'laudatory.club': 'School',
  'laudatory.proponentTime': 'Proponent Time',
  'laudatory.propounder': 'Proponent creator',
  'laudatory.status': 'Status',
  'laudatory.status.suggest':
    '{status,select, 1 {Pending} 2{Undefined} 3 {Reject}  other {Undefined}}',
  'laudatory.rejectReason': 'Reason of reject',
  'laudatory.idCard': 'ID card',
  'laudatory.grade': 'Grade',
  'laudatory.gender': 'Gender',
  'laudatory.male': 'Male',
  'laudatory.female': 'Female',
  'laudatory.dayOfBirth': 'Day of birth',
  'laudatory.workUnit': 'Class',
  'laudatory.phoneNumber': 'Phone number',
  'laudatory.position': 'Relationship',
  'laudatory.emailAddress': 'Email',
  'laudatory.approverTime': 'Approve time',
  'laudatory.approver': 'Proposer',
  'laudatory.approverAccept': 'Approver',
  'laudatory.personalInformation': 'Personal information',
  'laudatory.activities': 'Activities',
  'laudatory.reward': 'Laudatory',
  'laudatory.avatar': 'Avatar',
  'laudatory.score': 'Score',
  'laudatory.rank': 'Rank member',
  'laudatory.rewardName': 'Achievements',
  'laudatory.detailPage': 'View detail',
  'laudatory.changeClass': 'Change class',
  'laudatory.year': 'Year',
  'laudatory.quarter': 'Quarter',
  'laudatory.placeholderQuarter': 'Please select quarter',
  'laudatory.placeholderYear': 'Please select year',
  'laudatory.totalScore': 'Total score',
};
