export default {
  'clubs.detailSingin': 'Thông tin đăng nhập',
  'clubs.status': 'Trạng thái hoạt động',
  'clubs.status.active': 'Đang hoạt động',
  'clubs.status.not-active': 'Ngưng hoạt động',
  'clubs.non-delete.title': 'Không thể xóa',
  'clubs.non-delete.content': 'Không thể xóa Chi bộ đang hoạt động',
  'clubs.delete.title': 'Xóa Chi bộ',
  'clubs.delete.content': 'Bạn có muốn xóa Chi bộ đã chọn',
  'clubs.account-infomation': 'Thông tin đăng nhập',

  'clubs.name': 'Chi bộ',
  'clubs.listOfClubs': 'Danh sách Chi bộ',
  'clubs.add': 'Thêm Chi bộ mới',
  'clubs.code': 'Mã Chi bộ',
  'clubs.enterpriseName': 'Tên đơn vị',
  'clubs.quantity': 'Chi Đội (Số lượng)',
  'clubs.provinceCode': 'Địa chỉ',
  'clubs.districtCode': 'Quận / Huyện',
  'clubs.communeCode': 'Phường / Xã',
  'clubs.placeholder.provinceCode': 'Nhập Tỉnh / Thành phố',
  'clubs.placeholder.districtCode': 'Nhập Quận /Huyện',
  'clubs.placeholder.communeCode': 'Nhập Phường / Xã',
  'clubs.road': 'Đường',
  'clubs.emailAddress': 'Email',
  'clubs.headOfficeAddress': 'Địa chỉ',
  'clubs.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Dừng hoạt động} other {Không xác định}}',
  'clubs.action': 'Hành động',
  'clubs.statusSH.param':
    '{status,select, 1 {Chính thức} 2 {Dự bị} 3 {Miễn sinh hoạt} other {Không xác định}}',
  'clubs.info': 'Chi tiết',
  'clubs.edit': 'Cập nhật',
  'clubs.represents': 'Danh sách Cấp ủy',
  'clubs.member': 'Danh sách Đảng viên',
  'clubs.table.quantity': 'Số lượng Đảng viên',
  'clubs.view': 'Chi tiết Chi bộ',
  'clubs.infomation': 'Thông tin Chi bộ',
  'clubs.countOfMember': 'Đảng viên (Số lượng)',

  'clubs.list-represents': 'Danh sách Cấp ủy',
  'represents.code': 'Mã',
  'represents.name': 'Họ và tên',
  'represents.gender': 'Giới tính',
  'represents.gender.male': 'Nam',
  'represents.gender.female': 'Nữ',
  'represents.dayOfBirth': 'Ngày sinh',
  'represents.phoneNumber': 'Số điện thoại',
  'clubs.label.emailAddress': 'Email',
  'represents.add': 'Thêm Cấp ủy',
  'represents.placeholder.dayOfBirth': 'Nhập ngày sinh',
  'represents.personal-infomation': 'Thông tin cá nhân',
  'represents.about-clubs': 'Về Chi bộ',
  'represents.title': 'Chức danh',
  'represents.prorogues': 'Nhiệm kỳ',
  'represents.emailAddress': 'Email',
  'represents.placeholder.prorogues': 'Nhập nhiệm kỳ',
  'represents.action': 'Hành động',
  'represents.info': 'Chi tiết',
  'represents.edit': 'Cập nhật',
  'represents.view': 'Chi tiết Cấp ủy',
  'represents.delete.title': 'Xóa Cấp ủy',
  'represents.delete.content': 'Bạn có muốn xóa Cấp ủy đã chọn',

  'clubs.member.heading': 'Danh sách Đảng viên',
  'clubs.list-members': 'Danh sách Đảng viên',
  'clubs.member.add': 'Thêm Đảng viên',
  'clubs.member.title': 'Đảng viên',
  'member.code': 'Mã Đảng viên',
  'member.idCard': 'Mã thẻ',
  'member.name': 'Họ và tên',
  'member.grade': 'Khối lớp',
  'member.workUnit': 'Chi Đội',
  'member.phoneNumber': 'Số điện thoại',
  'member.avatar': 'Ảnh đại diện',
  'member.personal-infomation': 'Thông tin cá nhân',
  'member.placeholder.grade': 'Chọn khối lớp',
  'leaderInfo.name': 'Họ và tên',
  'leaderInfo.position': 'Quan hệ',
  'leaderInfo.emailAddress': 'Email',
  'member.leaderInfo': 'Thông tin người đại diện',
  'member.info': 'Chi tiết',
  'member.edit': 'Cập nhật',
  'member.action': 'Hành động',
  'clubs.member.edit': 'Cập nhật Đảng viên',
  'member.non-delete.title': 'Không thể xóa',
  'member.non-delete.content': 'Không thể xóa Đảng viên đang hoạt động',
  'member.delete.title': 'Xóa Đảng viên',
  'member.delete.content': 'Bạn có muốn xóa Đảng viên đã chọn',
  'clubs.member.view': 'Chi tiết Đảng viên',
  'member.clubs': 'Chi bộ',
  'clubs.statusSinhHoat': 'Trạng thái sinh hoạt',

  // update on 09/10/2023
  'clubs.searhModal.title': 'Tìm kiếm Đảng viên',
  'clubs.search-label': 'Từ khóa',
  'clubs.search': 'Tìm',
  'clubs.result-search': 'Không tìm thấy Đảng viên.',
  'clubs.result-search-page': 'Kết quả tìm kiếm',
  'clubs.result-search-page.name': 'Tên Đảng viên',
  'clubs.type': 'Loại Đảng viên',
  'clubs.memberPersonal': 'Đảng viên',
  'clubs.club-represent': 'Cấp ủy',
  'represents.ageClubs': 'Tuổi Đảng',
  'member.label.headOfficeAddress': 'Hộ khẩu thường trú',
  'member.placeholder.headOfficeAddress': 'Nhập hộ khẩu thường trú',
  'member.label.transactionOfficeAddress': 'Nơi ở hiện nay',
  'member.placeholder.transactionOfficeAddress': 'Nhập nơi ở hiện nay',
  'member.label.headOfficeAddressRoad': 'Địa chỉ chi tiết',
  'member.label.transactionOfficeAddressRoad': 'Địa chỉ chi tiết',
  'member.label.districts': 'quận huyện',
  'member.label.communes': 'phường xã',
  'member.provinces': 'Chọn tỉnh / thành phố',
  'member.districts': 'Chọn quận / huyện',
  'member.communes': 'Chọn phường / xã',
  'member.company': 'Đơn vị công tác',
  'member.position': 'Chức vụ hiện tại',
  'represents.about-party': 'Về Đảng',
  'represents.acceptDate': 'Ngày kết nạp',
  'represents.placeholder.acceptDate': 'Chọn ngày',
  'represents.officialDate': 'Ngày chính thức',
  'represents.label.activitiesAt': 'Sinh hoạt tại',
  'represents.placeholder.activitiesAt': 'Chọn chi bộ',
  'represents.placeholder.ageClubs': 'Hệ thống tính',
  'member.label.livingStatus': 'Trạng thái sinh hoạt',
  'member.placeholder.livingStatus': 'Chọn trạng thái',
  'livingStatus.official': 'Chính thức',
  'livingStatus.reserve': 'Dự bị',
  'livingStatus.exempt': 'Miễn sinh hoạt',
  'member.party-information': 'Thông tin Đảng viên',
  'member.activities': 'Hoạt động',
  'member.title': 'Chức danh',
  'member.delete.executive': 'Xóa ban chấp hành',
  'member.delete.clubRepresents': 'Xóa Cấp ủy',
  'clubs.meritBadges': 'Thành tích',
  'represents.age': 'năm',
  'represents.ages': 'năm',
  'changeGrade.oldClubId': 'Chi bộ hiện tại',
  'changeGrade.newClubId': 'Chi bộ chuyển đến',
};
