export default {
  'clubs.detailSingin': 'Singin information',
  'clubs.status': 'Status',
  'clubs.status.active': 'Active',
  'clubs.status.not-active': 'De-avtive',
  'clubs.non-delete.title': 'Can not delete',
  'clubs.non-delete.content': 'Can not delete active club',
  'clubs.delete.title': 'Delete club',
  'clubs.delete.content': 'Do you want to delete this club?',
  'clubs.account-infomation': 'Singin information',

  'clubs.name': 'Club',
  'clubs.listOfClubs': 'List of clubs',
  'clubs.add': 'Add club',
  'clubs.code': 'Club code',
  'clubs.enterpriseName': 'Club name',
  'clubs.quantity': 'Quantity',
  'clubs.provinceCode': 'Address',
  'clubs.districtCode': 'District',
  'clubs.communeCode': 'Commune',
  'clubs.placeholder.provinceCode': 'Please enter province',
  'clubs.placeholder.districtCode': 'Please enter district',
  'clubs.placeholder.communeCode': 'Please enter commune',
  'clubs.road': 'Road',
  'clubs.emailAddress': 'Email',
  'clubs.headOfficeAddress': 'Address',
  'clubs.status.param': '{status,select, 1 {Active} 2 {De-active} other {Unknow}}',
  'clubs.statusSH.param': '{status,select, 1 {Official} 2 {Exempt} 3 {Reserve} other {Undefined}}',
  'clubs.action': 'Action',
  'clubs.info': 'Information',
  'clubs.edit': 'Edit',
  'clubs.represents': 'List of Represent',
  'clubs.member': 'List of member',
  'clubs.table.quantity': 'Count of member',
  'clubs.view': 'Detail club',
  'clubs.infomation': 'Detail club',
  'clubs.countOfMember': 'Count of member',

  'clubs.list-represents': 'List of Represents',
  'represents.code': 'Code',
  'represents.name': 'Name',
  'represents.gender': 'Gender',
  'represents.gender.male': 'Male',
  'represents.gender.female': 'Female',
  'represents.dayOfBirth': 'Day of birth',
  'represents.phoneNumber': 'Phonenumber',
  'clubs.label.emailAddress': 'Email',
  'represents.add': 'Add represent',
  'represents.placeholder.dayOfBirth': 'Please enter day of birth',
  'represents.personal-infomation': 'Personal information',
  'represents.about-clubs': 'About club',
  'represents.title': 'Title',
  'represents.prorogues': 'Prorogues',
  'represents.emailAddress': 'Email',
  'represents.placeholder.prorogues': 'Please enter prorogues',
  'represents.action': 'Action',
  'represents.info': 'Information',
  'represents.edit': 'Edit',
  'represents.view': 'Detail member',
  'represents.delete.title': 'Delete member',
  'represents.delete.content': 'Do you want to delete this club',

  'clubs.member.heading': 'List of member',
  'clubs.list-members': 'List of Member',
  'clubs.member.add': 'Add member',
  'clubs.member.title': 'Member',
  'member.code': 'Member code',
  'member.idCard': 'Member id card',
  'member.name': 'Name',
  'member.grade': 'Grade',
  'member.workUnit': 'Work unit',
  'member.phoneNumber': 'Phonenumber',
  'member.avatar': 'Avatar',
  'member.personal-infomation': 'Personal information',
  'member.placeholder.grade': 'Please enter grade',
  'leaderInfo.name': 'Name',
  'leaderInfo.position': 'Relationship',
  'leaderInfo.emailAddress': 'Email',
  'member.leaderInfo': 'Represent detail',
  'member.info': 'Information',
  'member.edit': 'Edit',
  'member.action': 'Action',
  'clubs.member.edit': 'Edit member',
  'member.non-delete.title': 'Can not delete',
  'member.non-delete.content': 'Can not delete active member',
  'member.delete.title': 'Delete member',
  'member.delete.content': 'Do you want to delete this member',
  'clubs.member.view': 'Information member',
  'member.clubs': 'Club',
  'clubs.statusSinhHoat': 'Status',
  // update on 09/10/2023
  'clubs.searhModal.title': 'Search member',
  'clubs.search-label': 'Keyword',
  'clubs.search': 'Search',
  'clubs.result-search': 'Member not found.',
  'clubs.result-search-page': 'Search result',
  'clubs.result-search-page.name': 'Name',
  'clubs.type': 'Member type',
  'clubs.memberPersonal': 'Member',
  'clubs.club-represent': 'Club represent',
  'represents.ageClubs': 'Clubs age',
  'member.label.headOfficeAddress': 'Permanent residence registration',
  'member.placeholder.headOfficeAddress': 'Enter permanent residence registration',
  'member.label.transactionOfficeAddress': 'Current place of residence',
  'member.placeholder.transactionOfficeAddress': 'Enter current place of residence',
  'member.label.headOfficeAddressRoad': 'Detailed address',
  'member.label.transactionOfficeAddressRoad': 'Detailed address',
  'member.label.districts': 'Districts',
  'member.label.communes': 'Communes',
  'member.provinces': 'Select provinces',
  'member.districts': 'Select districts',
  'member.communes': 'Select communes',
  'member.company': 'Work at',
  'member.position': 'Position',
  'represents.about-party': 'About party',
  'represents.acceptDate': 'Accept date',
  'represents.placeholder.acceptDate': 'Select date',
  'represents.officialDate': 'Official date',
  'represents.label.activitiesAt': 'Active at',
  'represents.placeholder.activitiesAt': 'Select club',
  'represents.placeholder.ageClubs': 'Auto calculated',
  'member.label.livingStatus': 'Living status',
  'member.placeholder.livingStatus': 'Select status',
  'livingStatus.official': 'Official',
  'livingStatus.reserve': 'Reserve',
  'livingStatus.exempt': 'Exempt',
  'member.party-information': 'Information member',
  'member.activities': 'Activities',
  'member.title': 'Title',
  'member.delete.executive': 'Delete executive committee',
  'member.delete.clubRepresents': 'Delete club represent',
  'clubs.meritBadges': 'Merit Badges',
  'represents.age': 'year',
  'represents.ages': 'years',
  'changeGrade.oldClubId': 'Current Party Cell',
  'changeGrade.newClubId': 'Party Cell Transferred To',
};
