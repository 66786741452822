export default {
  'reports.name': 'Báo cáo',
  'reports.title': 'Nhập',
  'reports.nameFile': 'Tên file',
  'reports.createdAt': 'Ngày báo cáo',
  'reports.reporter': 'Người báo cáo',
  'reports.create': 'Thêm báo cáo',
  'reports.delete.title': 'Xóa báo cáo',
  'reports.delete.content': 'Bạn có muốn xóa báo cáo đã chọn?',
};
