export default {
  'statisticsAgeReport.name': 'Báo cáo tuổi Đảng',
  'statisticsAgeReport.code': 'Mã Đảng viên',
  'statisticsAgeReport.fullName': 'Họ và tên',
  'statisticsAgeReport.age': 'Tuổi Đảng',
  'statisticsAgeReport.year': 'năm',
  'statisticsAgeReport.month': 'tháng',
  'statisticsAgeReport.day': 'ngày',
  'statisticsAgeReport.years': 'năm',
  'statisticsAgeReport.months': 'tháng',
  'statisticsAgeReport.days': 'ngày',
  'statisticsAgeReport.type': 'Đối tượng',
  'statisticsAgeReport.activityStatus': 'Trạng thái sinh hoạt',
  'statisticsAgeReport.endTime': 'Ngày',
  'statisticsAgeReport.count': 'Số lượng',
};
