import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerLaudatoryTabs: IRouter = {
  path: '/laudatory/:tab',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'laudatory.name', //translate here for breadcrumb and sidebar
  menu: {
    activePath: 'laudatory',
    generatePath: () => 'laudatory/checkPermission', //default url
    // generatePath: () => 'laudatory/laudatory', //default url
    icon: <Icon.Gift />,
  },
};
