import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerStatisticsAgeReport: IRouter = {
  path: '/statistics-age-report',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'statisticsAgeReport.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.BarChart2 />,
  },
  permissionCode: PermissionCode.STATISTICS_AGE_REPORT,
};
